import Image from "next/legacy/image";
import {AiOutlineCheckCircle} from "react-icons/ai";
import Link from "next/link";
import {BsArrowRight} from "react-icons/bs";
import Packages from "./packages";
import { useTranslation } from '@/components/useTranslation';

const style = {
    section: `relative lg:h-[97vh]`,
    heroBackground: `\`absolute inset-0 w-full h-full object-cover`,
    wrapper: `relative mx-auto max-w-screen-xl px-4 py-32 md:py-40 sm:px-6 lg:flex lg:items-center lg:px-8 h-full`,
    wrapperInner: `max-w-md text-left bg-white backdrop-blur-sm bg-opacity-90 md:bg-opacity-100 px-8 sm:px-14 py-10 rounded-2xl`,
    title: `!text-3xl !font-extrabold sm:!text-3xl md:!pt-5`,
    text: `my-5 sm:text-md sm:leading-relaxed`,
    list: `mt-4 max-w-lg sm:text-sm sm:leading-relaxed flex items-center gap-2`,
    icon: `text-blue-700 text-xl`,
    button: `block w-full rounded py-3 text-lg font-bold flex items-center gap-3 text-blue-700 sm:w-auto`,
}

export default function HomeHero() {
    const t = useTranslation();

    return (
        <section className={style.section} id="startseite">
            <Image src={`/images/home/home.jpg`} className={style.heroBackground} priority={true} layout="fill" alt={'Google bewertungen kaufen icon'}/>
            <div className={style.wrapper}>
                <div className="hidden xl:block">
                <div className="absolute hero-image1">
                        <img src={'/images/home/animation/avatar-1.png'} width={160} height={98} alt={'Google bewertungen kaufen icon1'}/>
                    </div>
                    <div className="absolute hero-image2">
                        <img src={'/images/home/animation/avatar-2.png'} width={160} height={98} alt={'Google bewertungen kaufen icon2'}/>
                    </div>
                    <div className="absolute hero-image3">
                        <img src={'/images/home/animation/avatar-3.png'} width={160} height={98} alt={'Google bewertungen kaufen icon3'}/>
                    </div>
                    <div className="absolute hero-image4">
                        <img src={'/images/home/animation/avatar-4.png'} width={160} height={98} alt={'Google bewertungen kaufen icon4'}/>
                    </div>
                    <div className="absolute hero-image5">
                        <img src={'/images/home/animation/avatar-5.png'} width={160} height={98} alt={'Google bewertungen kaufen icon5'}/>
                    </div>
                    <div className="absolute hero-image1-shadow">
                        <img src={'/images/home/animation/blur-4.png'} width={120} height={118} alt={'Google bewertungen kaufen icon6'}/>
                    </div>
                    <div className="absolute hero-image2-shadow">
                        <img src={'/images/home/animation/blur-1.png'} width={120} height={118} alt={'Google bewertungen kaufen icon7'}/>
                    </div>
                    <div className="absolute hero-image3-shadow">
                        <img src={'/images/home/animation/blur-3.png'} width={120} height={118} alt={'Google bewertungen kaufen icon8'}/>
                    </div>
                    <div className="absolute hero-image4-shadow">
                        <img src={'/images/home/animation/blur-2.png'} width={120} height={118} alt={'Google bewertungen kaufen icon9'}/>
                    </div>
                </div>
                <div className={style.wrapperInner}>
                    <Image className="hidden md:block" src={'/images/global/platform/google-icon.png'} width={35} height={35} alt={'Google bewertungen kaufen icon'}/>
                    <div className={style.title} dangerouslySetInnerHTML={{__html: t.home_hero_title}}></div>
                    <p className={style.text}>{t.home_hero_description}</p>
                    <ul>
                        <li className={style.list}>
                            <div><AiOutlineCheckCircle className={style.icon}/></div>
                            <p>{t.home_hero_list_item1}</p>
                        </li>
                        <li className={style.list}>
                            <div><AiOutlineCheckCircle className={style.icon}/></div>
                            <p>{t.home_hero_list_item2}</p>
                        </li>
                        <li className={style.list}>
                            <div><AiOutlineCheckCircle className={style.icon}/></div>
                             <p>{t.home_hero_list_item3}</p>
                        </li>
                    </ul>

                    <div className="flex flex-wrap gap-4 mt-3 text-center">
                        <Link href="#preise" className={style.button}>
                        {t.home_hero_button}<BsArrowRight className={style.icon} />
                        </Link>
                    </div>
                </div>
                <Packages/>
            </div>
        </section>
    )
}