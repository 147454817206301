module.exports = {
  reactStrictMode: false,
  productionBrowserSourceMaps: true,
  disableStaticImages: true,
  i18n: {
    defaultLocale: 'de',
    locales: ['de', 'en', 'fr', 'es', 'it'],
    localeDetection: false,
  },
};
