import Image from "next/image";
import { useState, useEffect, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Tab } from "@headlessui/react";
import PriceCard from "./price-card";
import { useTranslation } from "@/components/useTranslation";
import { i18n } from "../../../next.config";
import { MyContext } from "@/pages/_app";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Price = () => {
  const [packages, setPackages] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("Google");
  const [isMobile, setIsMobile] = useState(false);
  const t = useTranslation();
  const [price, setPrice] = useState(300);
  const [proBewertung, setProBewertung] = useState(1);
  const [perPaket, setPerPaket] = useState(0);
  const [googleValue, setGoogleValue] = useState(0);
  const [jamedaValue, setJamedaValue] = useState(0);

  const {
    googleLoschenValue, setGoogleLoschenValue,
    jamedaLoschenValue, setJamedaLoschenValue,
    setIsSelected,
    setGlobalValue,
    setPriceLoschen,
    setSelectedPlatform,
  } = useContext(MyContext);

  const googleFirstPrice = 300;
  const jamedaFirstPrice = 350;
  const MobileThreshold = 900;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MobileThreshold);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const updatePriceAndProfit = (currentValue, platform) => {
    let basePrice = platform === "Jameda" ? jamedaFirstPrice : googleFirstPrice;
    let newPrice = basePrice * (currentValue + 1);

    setPrice(newPrice);
    setProBewertung(currentValue + 1);
    setPerPaket(currentValue + 1);
    setPriceLoschen(newPrice);
    setGlobalValue(currentValue + 1);
  };

  const handleChange = (event, platform) => {
    const currentValue = parseInt(event.target.value);
    if (platform === "Google") {
      setGoogleValue(currentValue);
      setGoogleLoschenValue(currentValue + 1);
    } else {
      setJamedaValue(currentValue);
      setJamedaLoschenValue(currentValue + 1);
    }
    setSelectedPlatform(platform);
    updatePriceAndProfit(currentValue, platform);
  };

  const resetValues = (platform) => {
    if (platform === "Google") {
      setGoogleValue(0);
      setGoogleLoschenValue(1);
      setPrice(googleFirstPrice);
      setPriceLoschen(googleFirstPrice);
    } else {
      setJamedaValue(0);
      setJamedaLoschenValue(1);
      setPrice(jamedaFirstPrice);
      setPriceLoschen(jamedaFirstPrice);
    }
    setProBewertung(1);
    setPerPaket(0);
    setGlobalValue(1);
  };

  useEffect(() => {
    function getPackages() {
      setPackages(t.data[0]);
    }
    getPackages();
  }, [selectedCategory, i18n, t]);

  const categories = [
    { key: "Google", icon: "/images/global/platform/google-icon.png", title: "Google" },
    { key: "Google Local Guide", icon: "/images/global/platform/local-guide-icon.png", title: "Google Local Guide" },
    { key: "Trustpilot", icon: "/images/global/platform/trustpilot-icon.png", title: "Trustpilot" },
    { key: "Arbeitgeber", icon: "/images/global/platform/arbeitgeber-icon.png", title: t.employer },
    { key: "Google Loschen", icon: "/images/global/platform/loschen-icon.png", title: "Google" },
    { key: "Jameda Loschen", icon: "/images/global/platform/jameda-icon.png", title: "Jameda" },
    { key: "Wunschplattform", icon: "/images/global/platform/wunsch-icon.png", title: t.wunschplattform },
  ];

  const renderRangeInput = (category, data) => {
    const currentValue = category === "Google Loschen" ? googleValue : jamedaValue;
    const displayValue = currentValue + 1;
    const percentage = (currentValue / 29) * 100;

    return (
      <div className="custom-range-container relative h-[30px] bg-gray-200 rounded-full">
        <div
          className="range-progress  h-full rounded-full "
          style={{ width: `${percentage}%` }}
        />
        <div
          className="range-thumb absolute top-0  w-[30px]  h-[30px] bg-blue-600 rounded-full flex items-center justify-center text-white font-bold cursor-pointer"
          style={{ left: `calc(${percentage}% - 10px)` }}
        >
          {displayValue}
        </div>
        <input
          type="range"
          className="absolute opacity-0 w-full h-full top-0 left-0 m-0 p-0 cursor-pointer"
          min="0"
          max="29"
          step="1"
          value={currentValue}
          onChange={(e) => handleChange(e, category === "Google Loschen" ? "Google" : "Jameda")}
        />
        <div className="absolute -left-7 top-1/2 -translate-y-1/2">
          <p className="text-xs text-gray-400">1</p>
        </div>
        {/* <div className="absolute -right-7 top-1/2 -translate-y-1/2">
          <p className="text-xs text-gray-400">30</p>
        </div> */}
      </div>
    );
  };

  return (
    <section className="preise" id="preise">
      <div className="px-8 py-12 mx-auto max-w-7xl lg:py-16">
        <div className="text-center">
          <h2>{t.price}</h2>
          <p className="pt-8 text-lg">{t.price_description}</p>
        </div>

        <Tab.Group onChange={(index) => {
          const newCategory = categories[index].key;
          setSelectedCategory(newCategory);
          if (newCategory === "Google Loschen" || newCategory === "Jameda Loschen") {
            resetValues(newCategory === "Google Loschen" ? "Google" : "Jameda");
          }
        }}>
          <Tab.List className="grid justify-center w-full grid-cols-7 mx-auto mt-10 rounded-full max-w-7xl z-full"
            style={{ boxShadow: "3.599px 15.59px 92px 0px rgba(0, 0, 0, 0.14)" }}>
            {categories.map((category, index) => (
              <Tab
                key={index}
                className={({ selected }) =>
                  classNames(
                    "flex h-full w-full justify-center items-center gap-3 transition-all py-3 md:py-5 hover:bg-gradient-to-t hover:from-blue-100 hover:to-stone-100",
                    selected ? "bg-gradient-to-t from-blue-100 to-stone-100" : "",
                    index === 0 ? "rounded-l-full" : "",
                    index === 6 ? "rounded-r-full" : ""
                  )
                }
              >
                <Image src={category.icon} alt={category.title} width={28} height={28} />
                <div className="flex-col items-start justify-start hidden lg:flex">
                  <p className="m-0 !text-xs font-bold !text-black leading-none">{category.title}</p>
                  <span className="text-xs font-medium">
                    {category.key === "Google Loschen" || category.key === "Jameda Loschen" ? t.clear2 : t.reviews}
                  </span>
                </div>
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels>
            {categories.map((category) => (
              <Tab.Panel key={category.key} className={`preise-category-${category.key}`}>
                {category.key === "Google Loschen" || category.key === "Jameda Loschen" ? (
                  <div className="flex flex-col justify-between h-full gap-12 mx-auto lg:mt-20 lg:items-end lg:flex-row md:items-center">
                    {packages[category.key]?.map((data, index) => (
                      <div key={index} className="flex flex-col justify-between h-full gap-12 mx-auto lg:mt-20 lg:items-end lg:flex-row md:items-center">
                        <div className="flex flex-col h-full gap-4 pt-8 lg:w-6/12 lg:gap-10">
                          <h4 className="text-4xl font-semibold">{data.titleleft}</h4>
                          <p>{data.leftdesc1}</p>
                          <p>{data.leftdesc2}</p>
                          <div className="hidden px-12 pt-8 pb-10 bg-white rounded-3xl lg:block"
                            style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                            <label htmlFor="customRange2" className="inline-block mb-3 font-semibold">{data.rangeTitle}</label>
                            {renderRangeInput(category.key, data)}
                          </div>
                        </div>

                        <div className="hidden lg:w-4/12 lg:block">
                          <div className="relative flex flex-col items-center justify-start max-w-md gap-4 px-8 py-10 mx-auto text-center text-white transition-all border preise-item-inner hover:cursor-pointer group bg-gradient-to-l from-blue-900 to-blue-600 md:w-full xl:px-14 rounded-3xl">
                            <h4 className="font-bold uppercase">{data.title}</h4>
                            <div className="flex items-start font-bold preise-item-inner-price group-hover:text-white !text-white">
                              <span className="text-4xl xl:text-6xl preise-item-inner-price-value">{price}</span>
                              <span className="text-3xl preise-item-inner-price-currency">€</span>
                            </div>
                            <div className="flex items-center justify-center preise-item-inner-pro-bewertung">
                              <p className="preise-item-inner-pro-bewertung-value group-hover:text-white !text-white">
                                <strong id="pro-bewertung">{proBewertung}</strong>{" "}
                                {proBewertung === 1 && data.name === "Bewertungen" ? "Bewertung" : data.name}
                              </p>
                            </div>
                            <p className="preise-item-inner-total relative -top-2 group-hover:text-white !text-white">{data.garantie}</p>
                            <div className="p-4 preise-item-inner-button">
                              <a onClick={() => setIsSelected(true)}
                                className="rounded-full font-medium py-3.5 px-6 sm:px-12 group-hover:bg-white group-hover:text-blue-700 text-sm group-hover:border-blue-700 !text-blue-700 bg-white"
                                href="/#kontakt">
                                Jetzt Buchen
                              </a>
                            </div>
                            <div>
                              <p className="text-white !text-sm">
                                <span className="text-base font-bold">
                                  {category.key === "Jameda Loschen" ? jamedaFirstPrice : googleFirstPrice}
                                </span>{" "}
                                {data.proBwt}
                              </p>
                            </div>
                            <ul className="space-y-3 pb-2 text-sm">
                              {data.features.map((feature, index) => (
                                <div key={index}
                                  dangerouslySetInnerHTML={{ __html: feature }}
                                  className="group-hover:text-white text-xs sm:text-sm active:text-white text-left !text-white">
                                </div>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Swiper
                    className="w-full mx-auto"
                    spaceBetween={50}
                    breakpoints={{
                      0: { spaceBetween: 10, slidesPerView: 1.1 },
                      768: { slidesPerView: 2 },
                      1024: { slidesPerView: 3 },
                    }}
                  >
                    {packages[category.key]?.map((data, index) => (
                      <SwiperSlide key={index} className="mx-auto preise-inner-content">
                        <PriceCard
                          title={data.title}
                          price={data.price}
                          value={data.value}
                          proBewertung={data.proBewertung}
                          total={Math.round(data.proBewertung * parseFloat(data.price.replace(",", ".")) * 100) / 100}
                          features={data.features}
                          active={(index === 1 && !isMobile) || (isMobile && true)}
                          oldPrice={category.key === "Google" || category.key === "Google Local Guide"}
                          discountImage={category.key === "Google" || category.key === "Google Local Guide"}
                          loschungActive={category.key === "Google Loschen"}
                          loschungQuantity={index === 0 ? data.loschungData2 : data.loschungData3}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </section>
  );
};

export default Price;