import Link from "next/link";
import Image from "next/image";
import { useTranslation } from '@/components/useTranslation';

const style = {
    wrapper: `grid grid-cols-7 gap-6 place-content-center w-full max-w-7xl mx-auto z-full bg-white px-8 sm:px-12 py-3 sm:py-4 rounded-t-3xl absolute left-1/2 transform -translate-x-1/2 bottom-0`,
    item: `lg:flex items-center lg:justify-start gap-3`,
    contentWrapper: `hidden lg:block pt-2 lg:pt-0`,
    title: `m-0 pb-0 !leading-none !text-xs font-bold text-black`,
    text: `!leading-none !text-xs m-0 p-0`,
}


export default function Packages() {

    const t = useTranslation();

    const platformPackages = [
        {
            icon: '/images/global/platform/google-icon.png',
            title: 'Google Map',
            id: "Google Key",
            url: `#preise`
        },
        {
            icon: '/images/global/platform/local-guide-icon.png',
            title: 'Google Local Guide',
            id: "Local Guide Key",
            url: `#preise`
        },
        {
            icon: '/images/global/platform/trustpilot-icon.png',
            title: 'Trustpilot',
            id: "Trustpilot Key",
            // url: `platform/${t.url_trustpilot}`
            url: `#preise`
        },
        {
            icon: '/images/global/platform/arbeitgeber-icon.png',
            title: t.employer,
            id: "Arbeitgeber Key",
            // url: `platform/${t.url_arbeitgeber}`
            url: `#preise`
        },
        {
            icon: '/images/global/platform/loschen-icon.png',
            title: 'Google',
            id: "Google Loschen Key",
            // url: `platform/${t.url_google_loschen}`
            url: `#preise`
        },
        {
            icon: '/images/global/platform/jameda-icon.png',
            title: 'Jameda',
            id: 'Jameda Loschen Key',
            url: `#preise`
        },
        {
            icon: '/images/global/platform/wunsch-icon.png',
            title: t.wunschplattform,
            id: "Wunschplattform Key",
            // url: `platform/${t.url_wunschplattform}`
            url: `#preise`
        }
    ]

    return (
        <div className={style.wrapper}>
            {
                platformPackages.map((item, index) => {
                    return (
                        <Link href={`${item.url}`} locales={t} className={style.item} key={item.id}>
                            <Image src={`${item.icon}`} alt={`${item.title} ${t.buy_reviews}`} width={36} height={36} />
                            <div className={style.contentWrapper}>
                                <p className={style.title}>{item.title}</p>
                                <span className={style.text}>{item.title === 'Google' || item.title === 'Jameda' ? t.clear2 : t.reviews}</span>
                            </div>
                        </Link>
                    )
                })
            }
        </div>
    )
}