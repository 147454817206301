import Image from "next/image";
import { useTranslation } from '../../useTranslation';

const style = {
    wrapper: `mx-auto max-w-7xl px-8 py-12 lg:py-16`,
    grid: `grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8 place-content-center items-center`,
    image: `relative overflow-hidden`,
    item: `lg:py-24 w-full mx-auto max-w-md`,
}

const Content2 = () => {

    const t = useTranslation();

    return (
        <section className='echte-nutzerbewertungen'>
            <div className={style.wrapper}>
                <div className={style.grid}>
                    <div className={style.image}>
                        <video autoPlay loop muted playsInline>
                            <source src="/video/ranking.mp4" type="video/mp4"/> 
                        </video>
                    </div>
                    <div className={style.item}>
                        <h2>{t.home_step2_title}</h2>
                            <div className="mt-6" dangerouslySetInnerHTML={{__html: t.home_step2_description}}></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Content2;
